import React from 'react';

const Task8 = ({ name, age, location }) => {
    const styles = {
        card: {
          border: '1px solid #ccc',
          padding: '16px',
          borderRadius: '8px',
          width: '250px',
          textAlign: 'center',
        },
      };
    const codeString = `
    import React from 'react';

    const Task8 = ({ name, age, location }) => {
      return (
        <div style={{ border: '1px solid #ccc', padding: '20px', width: '200px' }}>
          <h2>{name}</h2>
          <p>Age: {age}</p>
          <p>Location: {location}</p>
        </div>
      );
    };

    export default Task8;
  `;

    return (
        <div>
            <div style={styles.card}>
                <h2>{name}</h2>
                <p>Age: {age}</p>
                <p>Location: {location}</p>
            </div>
            
            <h3>Code:</h3>
            <pre style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "5px" }}>
                {codeString}
            </pre>
        </div>
    );
};

export default Task8;
