import React, { useState, useEffect } from 'react';

const Task14 = () => {
    const [count, setCount] = useState(0);
    const codeString = `import React, { useState, useEffect } from 'react';

const Task14 = () => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        console.log('Component Mounted');
        return () => {
            console.log('Component Unmounted');
        };
    }, []);

    useEffect(() => {
        console.log('Component Updated');
    }, [count]);

    return (
        <div>
            <p>Count: {count}</p>
            <button onClick={() => setCount(count + 1)}>Increment</button>
        </div>
    );
};

export default Task14;
`;

    useEffect(() => {
        console.log('Component Mounted');
        return () => {
            console.log('Component Unmounted');
        };
    }, []);

    useEffect(() => {
        console.log('Component Updated');
    }, [count]);

    return (
        <div>
            <p>Count: {count}</p>
            <button onClick={() => setCount(count + 1)}>Increment</button>
            <h3>Code:</h3>
            <pre style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "5px" }}>
                {codeString}
            </pre>
        </div>
    );
};

export default Task14;
