import React, { useState } from 'react';

const Task18 = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isDarkMode, setIsDarkMode] = useState(false);
    const codeString =`
    import React, { useState } from 'react';

    const Task18 = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isDarkMode, setIsDarkMode] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        alert(Welcome, {name});
    };

    return (
        <div style={{ background: isDarkMode ? 'black' : 'white', color: isDarkMode ? 'white' : 'black', height:'200px'}}>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit">Submit</button>
            </form>
            <button onClick={() => setIsDarkMode(!isDarkMode)}>Toggle Dark/Light Mode</button>
        </div>
    );
};

export default Task18;
`;

    const handleSubmit = (e) => {
        e.preventDefault();
        alert(`Welcome, ${name}`);
    };

    return (
        <>
        <div style={{ background: isDarkMode ? 'black' : 'white', color: isDarkMode ? 'white' : 'black', height:'200px'}}>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button type="submit">Submit</button>
            </form>
            <button onClick={() => setIsDarkMode(!isDarkMode)}>Toggle Dark/Light Mode</button>
        </div>
        <h3>Code:</h3>
            <pre style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "5px" }}>
                {codeString}
            </pre>
        </>
    );
};

export default Task18;
