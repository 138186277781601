import React, { useEffect, useState } from 'react';
import axios from 'axios';

function Task5() {
    const [data, setData] = useState([]);

    useEffect(() => {
        axios.get('https://jsonplaceholder.typicode.com/posts')
            .then((response) => setData(response.data));
    }, []);

    const codeString = `
        import React, { useEffect, useState } from 'react';
        import axios from 'axios';

        function Task5() {
            const [data, setData] = useState([]);

            useEffect(() => {
                axios.get('https://jsonplaceholder.typicode.com/posts')
                    .then((response) => setData(response.data));
            }, []);

            return (
                <div>
                    <h2>Posts:</h2>
                    <ul>
                        {data.map((post) => (
                            <li key={post.id}>{post.title}</li>
                        ))}
                    </ul>
                </div>
            );
        }

        export default Task5;
    `;

    return (
        <div>
            <h2>Posts:</h2>
            <ul>
                {data.map((post) => (
                    <li key={post.id}>{post.title}</li>
                ))}
            </ul>

            <h3>Code:</h3>
            <pre style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "5px" }}>
                {codeString}
            </pre>
        </div>
    );
}

export default Task5;
