import React, { useState, useRef } from 'react';

const Task10 = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const messageRef = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Name:', name);
        console.log('Email:', email);
        console.log('Message:', messageRef.current.value);
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Name: </label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </div>
                <div>
                    <label>Email: </label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div>
                    <label>Message: </label>
                    <textarea ref={messageRef} />
                </div>
                <button type="submit">Submit</button>
            </form>

            <h2>Code Example:</h2>
            <pre>
                <code>
                    {`import React, { useState, useRef } from 'react';

const Task10 = () => {
    // Controlled components
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    // Uncontrolled component
    const messageRef = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Name:', name);
        console.log('Email:', email);
        console.log('Message:', messageRef.current.value);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>Name: </label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </div>
            <div>
                <label>Email: </label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div>
                <label>Message: </label>
                <textarea ref={messageRef} />
            </div>
            <button type="submit">Submit</button>
        </form>;
};

export default Task10;`}
                </code>
            </pre>
        </div>
    );
};

export default Task10;
