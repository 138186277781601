import React, { useState, useEffect } from 'react';

const Task15 = () => {
    const [time, setTime] = useState(60);
    const codeString = `
    import React, { useState, useEffect } from 'react';

const Task15 = () => {
    const [time, setTime] = useState(60);

    useEffect(() => {
        const timer = setInterval(() => {
            setTime((prevTime) => prevTime - 1);
        }, 1000);

        if (time === 0) clearInterval(timer);

        return () => clearInterval(timer);
    }, [time]);

    return( 
        <div>Time Left: {time} seconds</div>);
};

export default Task15;
`;

    useEffect(() => {
        const timer = setInterval(() => {
            setTime((prevTime) => prevTime - 1);
        }, 1000);

        if (time === 0) clearInterval(timer);

        return () => clearInterval(timer);
    }, [time]);

    return( 
        <div>Time Left: {time} seconds
        <br />
        <h3>Code: </h3>
        <pre style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "5px" }}>
                {codeString}
            </pre>
        </div>);
};

export default Task15;
