import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
    const tasks = [
        { id: 1, name: 'Controlled Input', path: '/task1' },
        { id: 2, name: 'Uncontrolled Form', path: '/task2' },
        { id: 3, name: 'Controlled Form', path: '/task3' },
        { id: 4, name: 'useEffect Example', path: '/task4' },
        { id: 5, name: 'Axios Data Fetch', path: '/task5' },
        { id: 6, name: 'Fetch POST Request', path: '/task6' },
        { id: 7, name: 'Todo List', path: '/task7' },
        { id: 8, name: 'User Card', path: '/task8' },
        { id: 9, name: 'Registration Form', path: '/task9' },
        { id: 10, name: 'Contact Form with Controlled and Uncontrolled Components', path: '/task10' },
        { id: 11, name: 'Vite Process Outs Function', path: '/task11' },
        { id: 12, name: 'React Router Setup Guide', path: '/task12' },
        { id: 13, name: 'Simple Calculator', path: '/task13' },
        { id: 14, name: 'Lifecycle Methods Exploration', path: '/task14' },
        { id: 15, name: 'CountDown Timer', path: '/task15' },
        { id: 16, name: 'Implement an authentication context', path: '/task16' },
        { id: 17, name: 'Hotel Homepage with Props', path: '/task17' },
        { id: 18, name: 'Form with Name and Email + Light/Dark Mode Toggle', path: '/task18' },
    ];

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="text-center">Welcome to my website</h1>
                    <p className="text-center">Explore the tasks below:</p>
                    <ul className="list-group">
                        {tasks.map((task) => (
                            <li key={task.id} className="list-group-item">
                                <Link to={task.path}>{task.name}</Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Home;
