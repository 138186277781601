import React, { useState } from 'react';

const Task1 = () => {
    const [inputValue, setInputValue] = useState('');
    
    const codeString = `
        import React, { useState } from 'react';

        const Task1 = () => {
            const [inputValue, setInputValue] = useState('');

            return (
                <div>
                    <h1>Task 1: Controlled Input</h1>
                    <input
                        type="text"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        placeholder="Type something..."
                    />
                    <p>Current Value: {inputValue}</p>
                </div>
            );
        };

        export default Task1;
    `;

    return (
        <div>
            <h1>Task 1: Controlled Input</h1>
            <input
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder="Type something..."
            />
            <p>Current Value: {inputValue}</p>

            <h3>Code:</h3>
            <pre style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "5px" }}>
                {codeString}
            </pre>
        </div>
    );
};

export default Task1;
