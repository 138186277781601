import React from 'react';

const Task12 = () => {
  return (
    <div>
      <h1>React Router Setup Guide</h1>
      <div>
        <h2>Step 1: Install React Router</h2>
        <p>First, install the `react-router-dom` package in your React project:</p>
        <pre>
          <code>npm install react-router-dom</code>
        </pre>
      </div>

      <div>
        <h2>Step 2: Create the Pages (Home, About, User)</h2>
        <p>Create the components for the Home, About, and User pages:</p>
        <pre>
          <code>{`// Home.js
import React from 'react';
const Home = () => <h2>Home Page</h2>;
export default Home;`}</code>
        </pre>
        <pre>
          <code>{`// About.js
import React from 'react';
const About = () => <h2>About Page</h2>;
export default About;`}</code>
        </pre>
        <pre>
          <code>{`// User.js
import React from 'react';
import { useParams } from 'react-router-dom';

const User = () => {
  const { username } = useParams();
  return <h2>Hello, {username}!</h2>;
};

export default User;`}</code>
        </pre>
      </div>

      <div>
        <h2>Step 3: Set Up Routing in App.js</h2>
        <p>Next, set up the routing in your `App.js` file:</p>
        <pre>
          <code>{`// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import User from './pages/User';

const App = () => {
  return (
    <Router>
      <nav>
        <Link to="/">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/user/john">User</Link>
      </nav>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/user/:username" component={User} />
      </Switch>
    </Router>
  );
};

export default App;`}</code>
        </pre>
      </div>

      <div>
        <h2>Step 4: Explanation</h2>
        <ul>
          <li><strong>Home:</strong> Displays the Home page content.</li>
          <li><strong>About:</strong> Displays the About page content.</li>
          <li><strong>User:</strong> Displays a personalized greeting using the username from the URL parameter (e.g., `/user/john`).</li>
          <li><strong>useParams Hook:</strong> Used in the `User` component to access the dynamic `username` parameter from the URL.</li>
        </ul>
      </div>

      <div>
        <h2>Step 5: Run the Application</h2>
        <p>Once everything is set up, run the app using:</p>
        <pre>
          <code>npm start</code>
        </pre>
        <p>When you visit <code>http://localhost:3000</code>, you can navigate to the Home, About, and User pages. For the User page, you can use a URL like <code>/user/john</code> to display a personalized greeting.</p>
      </div>
    </div>
  );
};

export default Task12;
