// import './App.css';
import {BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Task1 from './pages/Task1';
import Task2Page from './pages/Task2';
import Task3 from './pages/Task3';
import Task4 from './pages/Task4';
import Home from './pages/Home';
import Task6 from './pages/Task6';
import Task5 from './pages/Task5';
import Task7 from './pages/Task7';
import Task8 from './pages/Task8';
import Task9 from './pages/Task9';
import Task11 from './pages/Task11';
import Task10 from './pages/Task10';
import Task12 from './pages/Task12';
import Task13 from './pages/Task13';
import Task14 from './pages/Task14';
import Task15 from './pages/Task15';
import Task16 from './pages/Task16';
import Task17 from './pages/Task17';
import Task18 from './pages/Task18';

function App() {
  const hotelData = {
    hotelName: "Ocean View Hotel",
    address: "123 Beach Road",
    rooms: [
      { type: 'Single', price: '$100' },
      { type: 'Double', price: '$150' },
      { type: 'Suite', price: '$250' },
    ]
  };
  return (
      <Router>
    <div className="App">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path='/task1' element={<Task1/>}/>
          <Route path='/task2' element={<Task2Page/>}/>
          <Route path='/task3' element={<Task3/>}/>
          <Route path='/task4' element={<Task4/>}/>
          <Route path='/task5' element={<Task5/>}/>
          <Route path='/task6' element={<Task6/>}/>
          <Route path='/task7' element={<Task7/>}/>
          <Route path='/task8' element={<Task8 name="Pranjal"age='19'location="India"/>}/>
          <Route path='/task9' element={<Task9/>}/>
          <Route path='/task10' element={<Task10/>}/>
          <Route path='/task11' element={<Task11/>}/>
          <Route path='/task12' element={<Task12/>}/>
          <Route path='/task13' element={<Task13/>}/>
          <Route path='/task14' element={<Task14/>}/>
          <Route path='/task15' element={<Task15/>}/>
          <Route path='/task16' element={<Task16/>}/>
          <Route path='/task17' element={<Task17 {...hotelData}/>}/>
          <Route path='/task18' element={<Task18/>}/>

        </Routes>
    </div>
      </Router>
  );
}

export default App;
