import React from 'react';
import PropTypes from 'prop-types';

// HotelInfo Component
const Task17 = ({ hotelName, address, rooms }) => {
    const codeString = `
import React from 'react';
import PropTypes from 'prop-types';

// HotelInfo Component
const Task17 = ({ hotelName, address, rooms }) => {
    return (
        <div>
            <h1>{hotelName}</h1>
            <p>{address}</p>
            <table>
                <thead>
                    <tr>
                        <th>Room Type</th>
                        <th>Price</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {rooms.map((room, index) => (
                        <tr key={index}>
                            <td>{room.type}</td>
                            <td>{room.price}</td>
                            <td>
                                <button onClick={() => alert('Booking dollar{room.type'})}>Book Now</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

Task17.propTypes = {
    hotelName: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    rooms: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string.isRequired,
            price: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default Task17;
    `;

    return (
        <div>
            <h1>{hotelName}</h1>
            <p>{address}</p>
            <table>
                <thead>
                    <tr>
                        <th>Room Type</th>
                        <th>Price</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {rooms.map((room, index) => (
                        <tr key={index}>
                            <td>{room.type}</td>
                            <td>{room.price}</td>
                            <td>
                                <button onClick={() => alert(`Booking ${room.type}`)}>Book Now</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <h3>Code:</h3>
            <pre style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "5px" }}>
                {codeString}
            </pre>
        </div>
    );
};

Task17.propTypes = {
    hotelName: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    rooms: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string.isRequired,
            price: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default Task17;