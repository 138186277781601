import React, { useRef } from 'react';

const Task2Page = () => {
    const nameRef = useRef();
    const emailRef = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Name:', nameRef.current.value);
        console.log('Email:', emailRef.current.value);
    };

    const codeString = `
        import React, { useRef } from 'react';

        const Task2Page = () => {
            const nameRef = useRef();
            const emailRef = useRef();

            const handleSubmit = (e) => {
                e.preventDefault();
                console.log('Name:', nameRef.current.value);
                console.log('Email:', emailRef.current.value);
            };

            return (
                <div>
                    <h1>Task 2: Uncontrolled Form</h1>
                    <form onSubmit={handleSubmit}>
                        <input type="text" ref={nameRef} placeholder="Name" />
                        <input type="email" ref={emailRef} placeholder="Email" />
                        <button type="submit">Submit</button>
                    </form>
                </div>
            );
        };

        export default Task2Page;
    `;

    return (
        <div>
            <h1>Task 2: Uncontrolled Form</h1>
            <form onSubmit={handleSubmit}>
                <input type="text" ref={nameRef} placeholder="Name" />
                <input type="email" ref={emailRef} placeholder="Email" />
                <button type="submit">Submit</button>
            </form>

            <h3>Code:</h3>
            <pre style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "5px" }}>
                {codeString}
            </pre>
        </div>
    );
};

export default Task2Page;
