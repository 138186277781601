import React, { useState } from 'react';

const Task13 = () => {
    const [result, setResult] = useState(0);
    const [input, setInput] = useState('');
    const codeString = `
    import React, { useState } from 'react';

const Task13 = () => {
    const [result, setResult] = useState(0);
    const [input, setInput] = useState('');

    const handleChange = (e) => {
        setInput(e.target.value);
    };

    const handleClick = (operation) => {
        const [num1, num2] = input.split(',').map(Number);
        let res = 0;

        switch (operation) {
            case 'add':
                res = num1 + num2;
                break;
            case 'subtract':
                res = num1 - num2;
                break;
            case 'multiply':
                res = num1 * num2;
                break;
            case 'divide':
                res = num1 / num2;
                break;
            default:
                break;
        }
        setResult(res);
    };

    return (
        <div>
            <input type="text" value={input} onChange={handleChange} placeholder="Enter numbers separated by comma" />
            <button onClick={() => handleClick('add')}>+</button>
            <button onClick={() => handleClick('subtract')}>-</button>
            <button onClick={() => handleClick('multiply')}>*</button>
            <button onClick={() => handleClick('divide')}>/</button>
            <p>Result: {result}</p>
        </div>
    );
};

export default Task13;
    `;

    const handleChange = (e) => {
        setInput(e.target.value);
    };

    const handleClick = (operation) => {
        const [num1, num2] = input.split(',').map(Number);
        let res = 0;

        switch (operation) {
            case 'add':
                res = num1 + num2;
                break;
            case 'subtract':
                res = num1 - num2;
                break;
            case 'multiply':
                res = num1 * num2;
                break;
            case 'divide':
                res = num1 / num2;
                break;
            default:
                break;
        }
        setResult(res);
    };

    return (
        <div>
            <input type="text" value={input} onChange={handleChange} placeholder="Enter numbers separated by comma" />
            <button onClick={() => handleClick('add')}>+</button>
            <button onClick={() => handleClick('subtract')}>-</button>
            <button onClick={() => handleClick('multiply')}>*</button>
            <button onClick={() => handleClick('divide')}>/</button>
            <p>Result: {result}</p>
            <h3>Code:</h3>
            <pre style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "5px" }}>
                {codeString}
            </pre>
        </div>
    );
};

export default Task13;
