import React, { useEffect, useState } from 'react';

function Task4() {
    const [data, setData] = useState([]);
    const codeString = `
        import React, { useEffect, useState } from 'react';

        function Task4() {
            const [data, setData] = useState([]);

            useEffect(() => {
                fetch('https://jsonplaceholder.typicode.com/posts')
                    .then((response) => response.json())
                    .then((data) => setData(data));
            }, []);

            return (
                <div>
                    <h2>Posts:</h2>
                    <ul>
                        {data.map((post) => (
                            <li key={post.id}>{post.title}</li>
                        ))}
                    </ul>
                </div>
            );
        }

        export default Task4;
    `;

    useEffect(() => {
        fetch('https://jsonplaceholder.typicode.com/posts')
            .then((response) => response.json())
            .then((data) => setData(data));
    }, []);

    return (
        <div>
            <h2>Posts:</h2>
            <ul>
                {data.map((post) => (
                    <li key={post.id}>{post.title}</li>
                ))}
            </ul>
            
            <h3>Code:</h3>
            <pre style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "5px" }}>
                {codeString}
            </pre>
        </div>
    );
}

export default Task4;
