import React from 'react';

const Task6 = () => {
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = { name: "Test", email: "test@example.com" };
        const response = await fetch("https://jsonplaceholder.typicode.com/posts", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formData),
        });
        console.log("Response:", await response.json());
    };

    const codeString = `
        import React from 'react';

        const Task6 = () => {
            const handleSubmit = async (e) => {
                e.preventDefault();
                const formData = { name: "Test", email: "test@example.com" };
                const response = await fetch("https://jsonplaceholder.typicode.com/posts", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(formData),
                });
                console.log("Response:", await response.json());
            };

            return (
                <div>
                    <h2>Task 6: Fetch POST Request</h2>
                    <button onClick={handleSubmit}>Submit Data</button>
                </div>
            );
        };

        export default Task6;
    `;

    return (
        <div>
            <h2>Task 6: Fetch POST Request</h2>
            <button onClick={handleSubmit}>Submit Data</button>

            <h3>Code:</h3>
            <pre style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "5px" }}>
                {codeString}
            </pre>
        </div>
    );
};

export default Task6;
