import React, { useState } from 'react';

const Task3 = () => {
    const [formData, setFormData] = useState({ name: '', email: '' });
    const [errors, setErrors] = useState({});

    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email) newErrors.email = 'Email is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            console.log('Form Data:', formData);
        }
    };

    const codeString = `
        import React, { useState } from 'react';

        const Task3 = () => {
            const [formData, setFormData] = useState({ name: '', email: '' });
            const [errors, setErrors] = useState({});

            const validate = () => {
                const newErrors = {};
                if (!formData.name) newErrors.name = 'Name is required';
                if (!formData.email) newErrors.email = 'Email is required';
                setErrors(newErrors);
                return Object.keys(newErrors).length === 0;
            };

            const handleSubmit = (e) => {
                e.preventDefault();
                if (validate()) {
                    console.log('Form Data:', formData);
                }
            };

            return (
                <div>
                    <h1>Task 3: Form Validation</h1>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <input
                                type="text"
                                placeholder="Name"
                                value={formData.name}
                                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                            />
                            {errors.name && <p style={{ color: 'red' }}>{errors.name}</p>}
                        </div>
                        <div>
                            <input
                                type="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                            />
                            {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                        </div>
                        <button type="submit">Submit</button>
                    </form>
                </div>
            );
        };

        export default Task3;
    `;

    return (
        <div>
            <h1>Task 3: Form Validation</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <input
                        type="text"
                        placeholder="Name"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                    {errors.name && <p style={{ color: 'red' }}>{errors.name}</p>}
                </div>
                <div>
                    <input
                        type="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    />
                    {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
                </div>
                <button type="submit">Submit</button>
            </form>

            <h3>Code:</h3>
            <pre style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "5px" }}>
                {codeString}
            </pre>
        </div>
    );
};

export default Task3;
