import React from 'react'

export default function Task16() {
    const codeString = `
    import React, { createContext, useContext, useState } from 'react';

    // Create context

    const AuthContext = createContext();

    // Create a provider component

    export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const login = () => setIsAuthenticated(true);
    const logout = () => setIsAuthenticated(false);

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
        {children}
        </AuthContext.Provider>
    );
};

// Create a custom hook to access auth context
export const useAuth = () => useContext(AuthContext);
`;
    return (
        <div>Task16
            <h3>Code:</h3>
            <pre style={{ backgroundColor: "#f5f5f5", padding: "10px", borderRadius: "5px" }}>
                {codeString}
            </pre>
        </div>
    )
}
