import React from 'react';

const ProcessOuts = ({ users }) => {
  const processOuts = (users) => {
    return users.map(({ name, age, location }) => `${name} - Age: ${age} Location: ${location}`);
  };

  return (
    <div>
      <h2>User Details</h2>
      <ul>
        {processOuts(users).map((userInfo, index) => (
          <li key={index}>{userInfo}</li>
        ))}
      </ul>

      <h2>Code Example:</h2>
      <pre>
        <code>
          {`import React from 'react';

const ProcessOuts = ({ users }) => {
  const processOuts = (users) => {
    return users.map(({ name, age, location }) => \`\${name} - Age: \${age} Location: \${location}\`);
  };

  return (
    <div>
      <h2>User Details</h2>
      <ul>
        {processOuts(users).map((userInfo, index) => (
          <li key={index}>{userInfo}</li>
        ))}
      </ul>
    </div>
  );
};

const users = [
  { name: 'John', age: 25, location: 'New York' },
  { name: 'Jane', age: 30, location: 'Los Angeles' },
  { name: 'Mike', age: 22, location: 'Chicago' }
];

export default function Task11() {
  return <ProcessOuts users={users} />;
}`}
        </code>
      </pre>
    </div>
  );
};

// Example usage:
const users = [
  { name: 'John', age: 25, location: 'New York' },
  { name: 'Jane', age: 30, location: 'Los Angeles' },
  { name: 'Mike', age: 22, location: 'Chicago' }
];

export default function Task11() {
  return <ProcessOuts users={users} />;
}
